import { Fragment } from "react";
import { Task } from "../../interfaces/models/Task";
import { TaskAssignment } from "../../interfaces/models/TaskAssignment";
import { Sequence, TaskSequence } from "../../interfaces/models/TaskSequence";
import {
  DASHBOARD_ROOT,
  DESK_COACH,
  WORKHEALTH_COACH,
} from "../Routes/routingConstants";
import TimelineItem, { TimelineItemStatus } from "./TimelineItem";

interface TimelineBlockProps {
  task_sequence?: TaskSequence;
  task_assignments: TaskAssignment[];
  isWorkHealth?: boolean;
}

const BURNOUT_PERCENTAGE = 0.1;
const LONG_DAY_PERCENTAGE = 0.4;

export default function TimelineBlock({
  task_sequence,
  task_assignments,
  isWorkHealth = false,
}: TimelineBlockProps) {
  if (task_sequence === null || task_sequence.sequence.length === 0) {
    return <p>User has no sequence for today.</p>;
  }
  const taskSequenceSize = task_sequence.sequence.length;

  // Apply the Work Life status to the final [BURNOUT_PERCENTAGE]% of tasks in the sequence
  const worklifeThreshold = Math.ceil(taskSequenceSize * BURNOUT_PERCENTAGE);
  // Apply the Work Life Balance status to the final [LONG_DAY_PERCENTAGE]% of tasks in the sequence
  const worklifeBalanceThreshold = Math.ceil(
    taskSequenceSize * LONG_DAY_PERCENTAGE
  );

  const getTaskUrl = (task: Task) => {
    return `${DASHBOARD_ROOT}/${isWorkHealth ? WORKHEALTH_COACH : DESK_COACH}/${
      task_sequence?._id
    }?taskId=${task._id}`;
  };

  const getLatestTaskAssignmentForSequenceTask = (sequenceTask: Sequence) => {
    // Find the latest task assignment that matches this sequence task's id
    return task_assignments
      .filter(
        (task_assignment) => sequenceTask.task._id === task_assignment.task._id
      )
      .reduce(
        (prevTA, currentTA) => {
          return new Date(prevTA.assigned_at) < new Date(currentTA.assigned_at)
            ? currentTA
            : prevTA;
        },
        task_assignments.find(
          (task_assignment) =>
            sequenceTask.task._id === task_assignment.task._id
        )
      );
  };

  const getTaskAssignmentStatus = (sequenceTask: Sequence) => {
    const assignment = getLatestTaskAssignmentForSequenceTask(sequenceTask);
    const taskSequenceSize: number = task_sequence.sequence.length;

    if (assignment) {
      if (assignment.task_complete === true) {
        return TimelineItemStatus.Completed;
      } else if (assignment?.assigned_at) {
        return TimelineItemStatus.Scheduled;
      }
    }

    if (sequenceTask.order >= taskSequenceSize - worklifeThreshold) {
      return TimelineItemStatus.WorkLifeBalanceEnd;
    } else if (
      sequenceTask.order >=
      taskSequenceSize - worklifeThreshold - worklifeBalanceThreshold
    ) {
      return TimelineItemStatus.WorkLifeBalance;
    }
    return TimelineItemStatus.Upcoming;
  };

  const timelineTasks = task_sequence?.sequence?.map((sequence) => ({
    ...getLatestTaskAssignmentForSequenceTask(sequence),
    ...{
      ...sequence,
      task: { ...sequence.task, path: getTaskUrl(sequence.task) },
    },
  }));

  const TimelineItems = timelineTasks?.map((task, idx) => {
    return (
      <TimelineItem
        key={idx}
        task={task.task}
        status={getTaskAssignmentStatus(task)}
        assignedAt={task?.user_acknowledged_at ?? task.assigned_at}
      />
    );
  });

  return <Fragment>{TimelineItems}</Fragment>;
}
