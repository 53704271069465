import { Card, CardBody } from "reactstrap";
import spotlight from "../../assets/img/brand/spotlight-card.png";

// interface SpotlightProps {
//   image: string;
//   title: string;
//   description?: string;
//   link?: string;
// }

export default function SpotlightCard() {
  // TODO: Data displayed in this component is temporarily hardcoded - in future DC wish to have the ability to adjust this

  return (
    <Card className="h-100">
      <CardBody>
        <h3 style={{ marginBottom: "1rem" }}>Spotlight</h3>
        <div className="d-flex flex-column">
          <img
            style={{
              width: "100%",
              alignSelf: "center",
              borderRadius: "0.375rem",
              marginBottom: "1rem",
            }}
            src={spotlight}
            alt="Spotlight art for target"
          />
          <h2 style={{ fontWeight: "bold" }}>Healthy snacks</h2>
          <p>Tips to sustain your energy throughout the day.</p>
          <a
            style={{ fontSize: "0.9rem" }}
            href="https://www.deskcoach.com.au/post/5-healthy-snacks-to-take-to-work"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here for more info
          </a>
        </div>
      </CardBody>
    </Card>
  );
}
