import { Card, CardBody } from "reactstrap";
import emPossible from "../../assets/img/brand/em-card.png";

export default function SocialEnterpriseCard() {
  return (
    <Card className="h-100">
      <CardBody>
        <h3 style={{ marginBottom: "1rem" }}>empower me</h3>
        <div className="d-flex flex-column justify-content-center items-center mt-2">
          <img
            style={{
              width: "100%",
              borderRadius: "0.375rem",
              marginBottom: "1rem",
            }}
            src={emPossible}
            alt={"social-enterprise-img"}
          />
        </div>
        <p>Take control, nudge yourself to better performance.</p>
        <a style={{ fontSize: "0.9rem" }} href="/dash/empower.me">
          Click here for more info
        </a>
      </CardBody>
    </Card>
  );
}
